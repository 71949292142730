.preloader
  position: absolute
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  height: 100%
  background-color: #f1f1f1
  z-index: 100

  &__header
    display: flex
    justify-content: center
    width: 100%
    padding: 20px
    border-bottom: 1px solid #ccc
    background-color: #fff

    img
      max-width: 120px
      max-height: 36px

  &__title
    margin-bottom: 6px
    font-weight: bold
    font-size: 28px
    text-decoration: underline
    text-transform: uppercase
    text-align: center

  &__subtitle
    margin-bottom: 10px
    font-size: 22px
    font-weight: bold
    text-align: center

.preloader-content
  width: calc(100% - 40px)
  max-width: 850px
  margin: 60px 0
  border: 1px solid #dddddd
  border-radius: 5px
  background-color: #fff

  @media (max-width: 768px)
    margin: 20px 0

.preloader-body
  padding: 40px

  @media (max-width: 768px)
    padding: 25px

.preloder-bar
  position: relative
  height: 36px
  margin-bottom: 15px
  background-color: #ddd
  border-radius: 5px
  color: white
  overflow: hidden

  &__bg
    width: 0
    height: 100%
    background-image: url("./lines.png")
    background-size: contain
    background-position: 0px 0px
    font-weight: bold
    text-align: center
    font-size: 14px
    line-height: 36px
    animation: animatedBackground 10s linear infinite

.preloader-checklist
  padding: 15px
  background-color: #f9f9f9
  text-align: left

  &__item-name
    font-weight: bold
    margin-bottom: 6px

  &__item-status
    margin-bottom: 15px

    .fa-times-circle
      color: red

    .fa-check-circle
      color: green

.preloader-footer
  display: flex
  justify-content: space-between
  padding: 20px
  background-color: #efefef

  @media (max-width: 768px)
    flex-direction: column
    align-items: center
    justify-content: center

  &__img
    p
      font-size: 12px
      text-align: center

    img
      height: 80px

  &__review
    text-align: right
    font-size: 14px

    @media (max-width: 768px)
      text-align: center

  &__review-rating
    margin-bottom: 6px
    font-weight: bold

    @media (max-width: 768px)
      font-size: 20px

    .fa-star
      color: #ffc107

  &__review-text
    max-width: 350px
    margin-bottom: 6px
    font-style: italic

// ANIMATIONS
@keyframes animatedBackground
  from
    background-position: 0 0

  to
    background-position: 100% 0
