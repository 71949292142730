.shipping-info
  display: flex
  flex-direction: column
  margin-bottom: 20px
  border: 1px solid #ddd
  border-radius: 4px

  @media (max-width: 768px)
    margin-left: -20px
    margin-right: -20px

  .big-green-btn
    margin-bottom: 10px

.sms-text
  margin-top: 25px
  font-size: 14px

.sms-text-description
  margin-top: 10px
  font-size: 13px
  font-style: italic
  color: gray
  line-height: normal
