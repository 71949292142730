.custom-modal
  padding-top: 20px

  &__title
    margin-bottom: 20px
    font-size: 24px
    font-weight: 700

  &__close
    position: sticky
    top: 0
    right: 0
    left: 100%
    background-color: white
    border: none
    font-size: 40px
    font-weight: bold

  &__row
    margin-bottom: 15px

  p
    margin-bottom: 15px
    line-height: 1.4

  ol
    margin-bottom: 10px
    font-size: 18px

  .link
    padding: 0
    margin: 0
    background-color: transparent
    color: blue
    text-decoration: underline
    border: none
    font-size: inherit
