.header
  display: flex
  align-items: center
  flex-direction: column
  border-bottom: 1px solid #cccccc

  &__logo-wrapper
    display: flex
    justify-content: space-between
    width: 1000px
    max-width: 100%
    margin: 0 auto
    padding: 0 20px

  &__logo
    max-width: 120px
    max-height: 36px
    margin: 10px 0

  &__stars
    height: 35px
    padding: 2px

.urgency-bar
  width: 100%
  padding: 10px 0
  background-color: red
  color: white
  text-align: center

  &__time
    margin-left: 4px
    color: yellow
