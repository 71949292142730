.big-green-btn
  display: block
  margin: 0 20px
  margin-top: 0
  padding: 20px 10px
  border-radius: 4px
  border: none
  font-size: 24px
  text-align: center
  background: #00c749
  font-weight: 700
  color: #fff
  text-transform: uppercase
  transition: 0.2s all

  &:hover
    background-color: darken(#00c749, 10)

  i
    margin-left: 5px

.go-back
  background-color: transparent
  align-self: center
  padding: 15px
  border: none
  font-weight: 700
  color: #9a9a9a
  font-size: 16px
  transition: 0.2s all

  &:hover
    color: darken(#9a9a9a, 10)

// FORM STYLES

.form
  margin: 20px 0
  padding: 0 20px

  &__header
    padding-top: 20px
    padding-bottom: 20px
    border-top: 1px solid #eee
    border-bottom: 1px solid #eee

  &__group
    display: flex

    @media (max-width: 768px)
      flex-direction: column

    .form__field
      margin-left: 15px

      @media (max-width: 768px)
        margin-left: 0

      &:first-child
        margin-left: 0

    &--one-line
      .form__field
        margin-bottom: 0

      @media (max-width: 768px)
        flex-direction: row

        .form__field
          @media (max-width: 768px)
            margin-left: 15px

  &__field
    position: relative
    width: 100%
    margin-bottom: 15px

    input, select
      width: 100%
      height: 42px
      padding: 12px
      font-size: 14px
      border: 1px solid #cacaca
      border-radius: 4px
      background-color: #fff

      &.error
        border-color: red

      &::placeholder
        font-size: 12px
        color: #5a5a5a

    select
      padding-left: 8px

  &__field-icon
    position: absolute
    right: 12px
    top: 12px
    color: #909090

  &__error
    padding-top: 8px
    font-size: 14px
    color: red

  &--noMargin
    margin-top: 0
    margin-bottom: 0
