/* FREE PRODUCT MODAL
.free-product-modal
  text-align: left

.free-product-modal-warning
  padding: 8px
  background-color: #000000
  text-align: center
  color: #fff
  font-size: 15px
  font-weight: 700
  letter-spacing: 0.75px

.free-product-modal-header
  padding: 20px 50px
  background-color: #f0f0f0

  h4
    margin-bottom: 6px
    font-size: 35px
    font-weight: bold
    color: #000000

    span
      width: 26px
      margin: 0 2px
      padding: 0 2px
      display: inline-block
      color: #fff
      background-color: red
      font-size: 32px
      font-weight: 700

  p
    color: #000000
    line-height: normal
    font-size: 18px
    opacity: 0.7

.free-product-modal-description
  position: relative
  margin-top: 20px
  margin-bottom: 20px
  line-height: normal
  padding: 0 50px
  color: #000000
  font-size: 14px

  .fa-chevron-double-down
    position: absolute
    right: 25px
    top: -50px
    color: #717171
    font-size: 24px
    z-index: 10

.free-product-modal-buttons
  margin-top: 20px
  margin-bottom: 30px
  padding: 0 50px

.free-product-modal-buttons-btn
  margin-bottom: 15px
  padding: 20px
  border-radius: 4px
  background-color: #00c857
  color: #ffffff
  font-size: 13px
  font-weight: bold
  letter-spacing: 1.16px
  text-align: center
  text-transform: uppercase
  cursor: pointer
  transition: 0.2s all

  &--bold
    border-width: 3px !important
    border-color: rgba(0, 0, 0, 0.297) !important
    color: rgba(0, 0, 0, 0.528) !important
    font-size: 15px !important

  &:hover
    background-color: #009b43

#warranty-no
  background-color: transparent
  color: rgba(0, 0, 0, 0.38)
  border: 1px solid rgba(0, 0, 0, 0.12)

  &:hover
    background-color: rgb(228, 228, 228)

.free-product-modal-buttons-error
  padding-bottom: 5px
  font-size: 12px
  color: red

@media (max-width: 430px)
  .free-product-modal-warning
    font-size: 13px

  .free-product-modal-header
    padding-left: 10px
    padding-right: 10px

    h4
      font-size: 20px

      span
        font-size: 24px
        width: 20px

    p
      font-size: 15px

  .free-product-modal-description
    margin-top: 10px
    margin-bottom: 20px
    padding-left: 20px
    padding-right: 20px
    font-size: 12px

  .free-product-modal-buttons
    margin-top: 10px
    padding-left: 20px
    padding-right: 20px

.free-product-element
  padding: 0 30px

  .free-product-list-product-btn
    padding: 20px
