.special-offer
  display: flex
  flex-direction: column
  align-items: center
  width: 700px
  margin: 0 auto
  max-width: 100%
  padding-top: 30px

  &__title
    margin-bottom: 10px
    font-weight: bold
    font-size: 44px
    text-align: center

    @media (max-width: 768px)
      font-size: 32px

  &__subtitle
    margin-bottom: 30px
    font-size: 44px
    font-weight: bold
    text-align: center

    @media (max-width: 768px)
      font-size: 32px

  &__img
    width: 500px
    max-width: 100%
    margin-bottom: 15px

  &__select
    width: 450px
    max-width: 100%
    margin-bottom: 20px
    padding: 10px
    border-color: #818181
    border-radius: 4px
    font-size: 16px
    outline: none

  &__buttons
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

    &--add
      width: 550px
      max-width: 100%
      margin-bottom: 20px
      padding: 22px
      border: none
      border-radius: 8px
      background-color: #3cd108
      color: #ffffff
      font-size: 20px
      font-weight: 900
      cursor: pointer
      transition: 0.2s all
      outline: none

      @media (max-width: 768px)
        font-size: 26px
        padding: 15px 8px

      &:hover
        background: darken(#3cd108, 10)

    &--skip
      margin-bottom: 20px
      padding: 22px
      border: none
      border-radius: 8px
      background-color: #8b8b8b
      color: #ffffff
      font-size: 20px
      font-weight: 900
      text-decoration: none
      cursor: pointer
      transition: 0.2s all

      @media (max-width: 768px)
        padding: 14px

      &:hover
        background: darken(#8b8b8b, 10)

  &__disclaimer
    font-size: 12px
    line-height: 1.2
