.product-select
  display: flex
  flex-direction: column
  border: 1px solid #ddd
  border-radius: 4px

  &--opc
    border: none

  @media (max-width: 768px)
    margin-left: -20px
    margin-right: -20px

  .big-green-btn
    margin-top: 20px
    margin-bottom: 20px

    &:disabled
      background-color: #ddd
      cursor: not-allowed

.product-body
  display: flex
  align-items: center
  padding: 15px

.product-header
  padding: 10px
  background-color: #2b89cc
  color: white
  text-align: center
  font-weight: bold

.product
  border-top: 1px solid #ddd
  border-bottom: 1px solid #ddd
  cursor: pointer

  &:last-of-type
    border-bottom: 1px solid #ddd

  .fa-dot-circle
    display: none

  .fa-circle, .fa-dot-circle
    font-size: 18px

  &.active
    background-color: #fff7b2

    .fa-dot-circle
      display: block

    .fa-circle
      display: none

  &.popular
    border: 1px solid #2b89cc

  &__checkbox
    margin-right: 10px
    color: #2b89cc

  &__title
    margin-bottom: 4px
    font-size: 14px
    line-height: 1.4

    b
      font-size: 16px

  &__shipping
    font-size: 14px
    color: green
    font-weight: 700

  &__msrp
    color: red
    text-decoration: line-through

  &__price
    font-weight: 400

  &__savings
    color: green

.product-left
  flex: 1

.product-right
  text-align: right
  font-size: 14px
  font-weight: bold
  line-height: 1.4

.soldOut
  opacity: 0.5
  cursor: not-allowed

// one product checkout styles

.opcProduct
  display: flex
  border: none
  align-items: center
  margin-bottom: 10px
  padding: 10px
  cursor: pointer

  &--active
    border: 3px solid #2b89cc
    border-radius: 10px

  &:last-child
    margin-bottom: 0

  &__image
    position: relative
    flex: none
    border: 1px solid #c0c0c0
    border-radius: 10px
    padding: 10px
    margin-right: 10px

    img
      height: 75px
      
  &__title
    margin-right: 10px

  &__quantity
    position: absolute
    top: -10px
    right: -10px
    background-color: rgba(67, 67, 67, 0.862)
    height: 30px
    width: 30px
    border-radius: 50%
    color: white
    text-align: center
    line-height: 30px
    font-weight: bold
    font-size: 25px

  &__msrp
    color: red
    text-decoration: line-through

  &__shipping
    display: flex
    justify-content: flex-end
    margin-top: 10px
    margin-bottom: 20px

    b
      margin-right: 4px

.opcProduct-left
  display: flex
  align-items: center
  flex: 1

.opcProduct-right
  text-align: right
  font-weight: bold
  line-height: 1.2

.cta-btn-container
  display: flex
  flex-direction: column

  .big-green-btn
    margin-bottom: 15px
