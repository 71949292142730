.free-product-list-list
  margin: 20px 0
  padding: 10px 50px
  overflow-y: scroll
  border-bottom: 1px solid #ccc

  &--modal
    max-height: 460px

    @media (max-width: 430px)
      max-height: 310px
      margin-bottom: 10px
      margin-top: 10px
      padding-left: 10px
      padding-right: 10px

.free-product-list-product
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-between
  margin-bottom: 30px
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16)
  border-radius: 10px
  border: 2px solid #d1d1d1
  background-color: #ffffff
  cursor: pointer
  transition: all 0.2s

  &:last-child
    margin-bottom: 0

  &:hover
    box-shadow: 0 1px 18px rgba(0, 0, 0, 0.16)

  &.sold-out
    opacity: 0.7

  .fa-check-square
    display: none

  &.active
    .fa-square
      display: none

    .fa-check-square
      display: inline-block

.free-product-list-product-left
  flex: 1
  padding: 25px
  padding-right: 0
  line-height: normal

  @media (max-width: 430px)
    padding: 12px
    padding-right: 0

.free-product-list-product-img
  display: flex
  justify-content: flex-end
  align-items: center
  flex: none
  min-width: 134px
  padding: 10px

  @media (max-width: 430px)
    min-width: auto
    width: 90px

  img
    width: auto
    max-height: 110px
    max-width: 110px

    @media (max-width: 430px)
      max-width: 100%
      max-height: 100%

.free-product-list-product-stock
  position: absolute
  right: 15px
  top: -8px
  padding: 2px 4px
  border-radius: 4px
  border: 1px solid #ffffff
  background-color: #ff0000
  color: #ffffff
  font-size: 12px
  letter-spacing: 0.24px
  font-weight: bold
  text-transform: uppercase

  i
    color: yellow

.free-product-list-product-title
  display: flex
  align-items: center
  margin-bottom: 8px
  color: #000000
  font-size: 21px
  font-weight: 700

  @media (max-width: 430px)
    align-items: baseline
    font-size: 16px

  p
    margin-bottom: 0

.free-product-list-product.sold-out
  cursor: not-allowed
  user-select: none
  pointer-events: none

  .free-product-list-product-title p, .free-product-list-product-description
    text-decoration: line-through

  .free-product-list-product-btn
    background-color: #aaa

.free-product-list-product-title i
  margin-right: 6px

.free-product-list-product-description
  color: #838383
  font-size: 17px
  line-height: 24px

  @media (max-width: 430px)
    font-size: 13px
    line-height: normal

  b
    color: #000

  s
    color: red

.free-product-list-description
  position: relative
  margin-top: 20px
  margin-bottom: 20px
  padding: 0 50px
  color: #000000
  font-size: 14px

  .fa-chevron-double-down
    position: absolute
    right: 25px
    top: -50px
    color: #717171
    font-size: 24px
    z-index: 10

.free-product-list-product-btn
  width: 100%
  padding: 5px 10px
  background-color: #00c857
  margin-top: 8px
  padding: 10px
  border-radius: 4px
  border: none
  background-color: #00c857
  color: #ffffff
  font-size: 13px
  font-weight: bold
  letter-spacing: 1.16px
  text-align: center
  text-transform: uppercase
  cursor: pointer
  transition: 0.2s all

  &:hover
    background-color: #009b43

  @media (max-width: 430px)
    padding: 10px 4px
