.step-header
  display: flex
  flex-direction: column
  padding: 20px
  background-color: #f7f7f7
  text-align: center
  font-size: 20px

  b
    margin-right: 4px

  img
    width: 175px
    align-self: center
    margin-top: 10px
