.Default
  *
    box-sizing: border-box
    outline: none

  body
    display: flex
    flex-direction: column
    min-height: 100vh
    min-width: 320px
    color: #333333
    font-size: 14px
    font-family: "Roboto", sans-serif
    line-height: normal

  main
    position: relative
    display: flex
    flex-direction: column
    flex: 1

    @media (max-width: 768px)
      background-color: #eeeeee

  img, video
    max-width: 100%
    max-height: 100%
    z-index: 10

  button, a
    cursor: pointer

  b, strong
    font-weight: bold

  .text-center
    text-align: center

  .container
    width: 100%
    max-width: 960px
    margin: 0 auto
    padding: 0

  // disable existing page styles
  header.header
    display: none

  .page-main > .container
    width: 100%
    max-width: 100%
    padding: 0

  // header
  .page-header
    padding: 5px
    background-color: #ff0000
    font-size: 14px
    color: white

    @media (max-width: 768px)
      padding-bottom: 0
      padding-left: 0
      padding-right: 0

    &__steps
      height: 20px

      @media (max-width: 768px)
        margin-top: 4px
        background-color: #fff

  .page-header-inner
    display: flex
    justify-content: space-between
    align-items: center

    @media (max-width: 768px)
      flex-direction: column

  // greetings

  .greetings
    padding-top: 30px
    padding-bottom: 20px
    background-color: #fff
    text-align: center

    @media (max-width: 768px)
      padding-top: 15px
      padding-bottom: 15px

    h1
      position: relative
      margin-bottom: 4px
      padding-left: 58px
      padding-right: 58px
      font-size: 28px
      font-weight: 700

      @media (max-width: 768px)
        margin-bottom: 6px
        padding-left: 20px
        padding-right: 20px
        font-size: 22px

    h2
      font-size: 23px

      @media (max-width: 768px)
        font-size: 15px
        padding-left: 20px
        padding-right: 20px

      b
        color: #ff0000

    h3
      margin-bottom: 4px
      font-size: 28px
      color: #ff0000
      font-weight: 700

      @media (max-width: 768px)
        font-size: 24px

  // product-info
  // left
  .product-info
    &__header
      h3
        margin-bottom: 2px
        color: #033333
        font-size: 40px
        font-weight: 700

        @media (max-width: 768px)
          font-size: 24px

    &__stars
      margin-bottom: 18px
      color: #9f9f9f

      @media (max-width: 768px)
        font-size: 12px

      .fa-star
        color: #f5b11e
        font-size: 18px

        @media (max-width: 768px)
          font-size: 14px

    &__benefits
      margin-bottom: 20px
      padding: 18px 0
      border-top: 1px solid #dadad6
      border-bottom: 1px solid #dadad6

      @media (max-width: 768px)
        padding: 16px 0

      li
        position: relative
        margin-bottom: 14px
        padding-left: 30px
        color: #033333
        font-size: 20px

        @media (max-width: 768px)
          margin-bottom: 8px
          padding-left: 24px
          font-size: 17px

        &::before
          content: ""
          position: absolute
          left: 0
          width: 20px
          height: 20px
          border-radius: 50%
          background-color: #00baff

          @media (max-width: 768px)
            width: 18px
            height: 18px

  .product-info-inner
    display: flex
    box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.16)
    border-radius: 10px
    background-color: #ffffff
    overflow: hidden

    @media (max-width: 768px)
      flex-direction: column-reverse

  .product-info-left
    display: flex
    flex-direction: column
    flex: 1
    padding: 30px

    @media (max-width: 768px)
      padding: 20px

  .product-info-right
    width: 440px

    @media (max-width: 768px)
      width: 100%

  .product-info-prices
    display: flex
    justify-content: center
    margin-bottom: 30px

    &__msrp
      padding-right: 25px
      border-right: 1px solid #dadad6
      text-align: center

      p
        color: #353535
        font-size: 18px

      b
        position: relative
        display: block
        color: #333333
        font-weight: bold
        font-size: 38px

        &::after
          content: ""
          position: absolute
          top: calc(50% - 2px)
          left: 0
          width: 100%
          height: 4px
          background-color: #ff0000

      s
        color: #ff0000
        font-size: 12px
        font-weight: 700
        letter-spacing: 1.2px

    &__offer
      padding-left: 25px
      text-align: center

      p
        color: #353535
        font-size: 18px

        span
          color: #1cb031
          text-transform: uppercase
          font-size: 14px
          font-weight: bold

      b
        display: block
        color: #000
        font-weight: bold
        font-size: 38px

      span
        color: #1cb031
        font-size: 12px
        font-weight: 700
        letter-spacing: 1.2px

  .product-info-cta
    align-self: center
    width: 100%
    max-width: 395px
    margin-bottom: 22px
    padding: 20px 45px
    border-radius: 10px
    background-color: #1cb031
    font-weight: bold
    font-size: 28px
    text-align: center
    color: white
    --animate-duration: 1.5s
    cursor: pointer
    transition: 0.2s all

    &:hover
      background-color: darken(#1cb031, 10)

    @media (max-width: 768px)
      padding: 20px

    @media (max-width: 320px)
      font-size: 24px

  // ships info
  .ships-info
    display: flex
    align-items: center
    padding: 10px 20px
    border: 2px solid #1f4ba2
    border-radius: 4px

    @media (max-width: 768px)
      margin-bottom: 20px

  .ships-info img
    height: 60px
    margin-right: 10px

  .ships-info p
    font-size: 14px
    text-align: left

  // end ships info
  // right

  .product-info-right
    padding: 30px
    background-color: #eeeeee

    @media (max-width: 768px)
      padding: 20px

  .product-right-images
    margin-bottom: 46px

    @media (max-width: 768px)
      margin-bottom: 0

  .product-info-gallery
    margin-bottom: 15px

    &__img
      display: flex !important
      align-items: center
      width: 100%
      max-height: 250px
      background-color: #000

      @media (max-width: 768px)
        height: 30%

      img
        width: 100%
        height: 100%

  .product-info-gallery-thumbs
    &__img
      height: 55px
      padding: 0 5px
      cursor: pointer

      @media (max-width: 768px)
        height: 50px

      img
        height: 100%

  .product-right-guarantee
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center

    &--mob
      display: none

      @media (max-width: 768px)
        display: flex

    &--desk
      @media (max-width: 768px)
        display: none

    p
      position: relative
      display: block
      align-self: center
      margin-bottom: 10px
      color: #333333
      font-size: 17px
      font-weight: 700

      &::before
        position: absolute
        content: ""
        left: -55px
        top: 50%
        transform: translateY(-50%)
        width: 42px
        height: 1px
        background-color: #d0d0d0

      &::after
        position: absolute
        content: ""
        right: -55px
        top: 50%
        transform: translateY(-50%)
        width: 42px
        height: 1px
        background-color: #d0d0d0

    img
      align-self: center

  .no-button-wrapper
    text-align: center

  .no-button
    width: 100%
    max-width: 355px
    align-self: center
    margin-top: 20px
    margin-bottom: 20px
    padding: 20px
    border: none
    border-radius: 10px
    background-color: #858585
    color: white
    font-weight: bold
    text-align: center
    font-size: 16px
    text-decoration: none
    transition: 0.2s all

    &:hover
      background-color: darken(#858585, 10)

    @media (max-width: 768px)
      width: calc(100% - 40px)

  .fa-chevron-double-down
    position: fixed
    right: 20px
    bottom: 40px
    font-size: 24px
    color: #686868

    @media (min-width: 769px)
      display: none
