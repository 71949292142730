.contact-form
  margin-top: 30px

  &__title
    margin-bottom: 20px
    font-size: 20px
    font-weight: 700

  &__field
    margin-bottom: 15px

    &:last-of-type
      margin-left: 10px

  input, textarea
    width: 300px
    max-width: 100%
    padding: 10px 15px
    border-radius: 4px
    font-family: inherit
    font-size: inherit
    border: 1px solid #ccc
    resize: vertical

  &__captcha
    margin-bottom: 15px

  &__submit
    width: 300px
    max-width: 100%
    margin-bottom: 10px
    padding: 10px 15px
    text-transform: uppercase

  &__error
    color: red
    font-size: 12px

  &__success
    color: green
    font-size: 12px
