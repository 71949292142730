.holiday-banner
  position: relative
  justify-content: center
  width: 960px
  max-width: 100%
  margin-left: auto
  margin-right: auto

  &__img
    width: 100%
    max-width: 100%
    object-fit: contain

  &__img--mob
    display: none

  &__discount
    position: absolute
    right: 15px
    top: 10px
    padding: 5px 15px
    border: 2px dashed red
    font-weight: bold
    color: red
    border-radius: 5px
    background-color: #fff
    font-size: 20px
    white-space: nowrap

    @media (max-width: 768px)
      top: auto
      right: 50%
      bottom: 25px
      transform: translateX(50%)

    &:after
      content: "Discount Applied"
      position: absolute
      right: 0
      color: #000
      bottom: -20px
      font-size: 11px
      font-weight: bold

      @media (max-width: 768px)
        right: 50%
        transform: translateX(50%)

  &__img--desk
    @media (max-width: 768px)
      display: none

  &__img--mob
    @media (max-width: 768px)
      display: block
