.page-footer
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
  background-color: #000000
  padding: 20px
  color: white

  &__copyright
    margin-bottom: 15px

  &__links
    display: flex
    margin-bottom: 15px

  &__link
    width: 33.33%
    margin: 0 8px
    text-decoration: underline
    background-color: transparent
    color: white
    font-size: 14px
    border: none
    transition: 0.2s all
    white-space: nowrap

    @media (max-width: 320px)
      font-size: 12px

    &:hover
      opacity: 0.7

  &__note
    margin-bottom: 15px
