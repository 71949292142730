.ships-info-wrapper
  display: flex
  flex-direction: column

.ships-info
  display: flex
  align-items: center
  margin-bottom: 20px
  padding: 10px 20px
  border: 2px solid #1f4ba2
  margin-left: 20px
  margin-right: 20px
  border-radius: 4px

.ships-info img
  height: 40px
  margin-right: 10px

.ships-info p
  font-size: 13px
  text-align: left
  line-height: normal

.ships-info-secure
  align-self: center
  width: 500px
  max-width: 100%
  padding: 20px
  padding-top: 0

/* end ships info
