.payment-info
  display: flex
  flex-direction: column
  border: 1px solid #ddd
  border-radius: 4px

  .big-green-btn
    margin-bottom: 20px

    &--paypal
      display: flex
      flex-direction: column
      align-items: center
      background-color: #ffc439
      padding: 15px 10px

      @media (max-width: 768px)
        padding: 10px

      &:hover
        background-color: darken(#ffc439, 10) !important

  @media (max-width: 768px)
    margin-left: -20px
    margin-right: -20px

.form
  &--billing
    max-height: 0
    overflow: hidden
    transition: 0.3s all

    &.visible
      max-height: 500px
      margin-top: 20px
      overflow: visible

.protection-package
  display: flex
  padding: 20px
  padding-top: 0

  &__left
    flex: none
    margin-right: 10px

    img
      height: 100px

  &__right
    display: flex
    flex-direction: column

  &__title
    display: flex
    justify-content: space-between
    align-items: center
    font-size: 18px
    font-weight: 600
    margin-bottom: 10px

  &__text
    font-size: 12px

  &__toggle
    &.react-toggle--checked
      .react-toggle-track
        background-color: #42a6f6
