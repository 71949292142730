.checkbox-label
  display: block
  position: relative
  padding-left: 30px
  cursor: pointer
  font-size: 14px
  line-height: 1.4
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  .link
    display: inline-block
    color: #00c749
    text-decoration: underline

  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

.checkmark
  position: absolute
  top: -2px
  left: 0
  width: 20px
  height: 20px
  border: 2px solid grey
  border-radius: 4px
  background-color: white

.checkbox-label
  &:hover input ~ .checkmark
    background-color: white

  input:checked ~ .checkmark
    background-color: white

.checkmark:after
  content: ""
  position: absolute
  display: none

.checkbox-label
  input:checked ~ .checkmark:after
    display: block

  .checkmark:after
    left: 5px
    top: 1px
    width: 3px
    height: 8px
    border: solid #00c749
    border-width: 0 3px 3px 0
    -webkit-transform: rotate(45deg)
    -ms-transform: rotate(45deg)
    transform: rotate(45deg)
