.reviews
  margin-bottom: 40px

  &__title
    margin-bottom: 30px
    text-align: center
    font-weight: 700
    font-size: 40px

  &__test-img
    @media (max-width: 768px)
      display: none

    &--mob
      display: none

      @media (max-width: 768px)
        display: block

  &__test-button
    display: none
    width: 100%
    padding: 10px
    border-radius: 5px
    border: 1px solid #777777
    margin-top: 10px
    text-align: center
    text-transform: uppercase

    @media (max-width: 768px)
      display: block

.review
  display: flex !important
  flex-direction: column
  align-items: center
  text-align: center
  margin-bottom: 30px
  padding: 0 15px

  &__img
    max-height: 250px
    margin-bottom: 15px
    border-radius: 10px

  &__stars
    margin-bottom: 10px
    color: #f5b11d

  &__title
    margin-bottom: 15px
    font-size: 20px
    font-weight: 700

  &__text
    margin-bottom: 15px
    line-height: 1.4

  &__author
    display: flex
    flex-direction: column
    font-style: italic

  &__author-name
    margin-bottom: 5px
    font-weight: 700
