.color-select
  width: 100%
  padding: 20px
  text-align: center
  border-top: 1px solid #ddd

  &__title
    margin-bottom: 14px
    font-weight: bold
    font-size: 17px

  &__item
    margin: 0 5px
    padding: 5px
    border: 1px solid #d8d8d8
    cursor: pointer

    img
      max-height: 60px
      margin-bottom: 4px

    p
      font-size: 14px

    &.active
      border: 1px solid #4c99c5
      box-shadow: 0px 0px 2px 2px #4c99c5

.color-select-items
  display: flex
