// DETAILS
.product-details
  display: flex
  margin-bottom: 15px

  @media (max-width: 768px)
    align-items: center

  &__left
    flex: 1

  &__title
    margin-bottom: 6px
    font-size: 24px
    font-weight: 700
    line-height: 1.2

    @media (max-width: 768px)
      font-size: 18px

  &__mfr
    margin-bottom: 8px
    font-size: 14px

    span
      color: #1797ff

  &__ratings
    display: flex
    align-items: center
    margin-bottom: 10px

    .fa-star
      color: #f5b11d

      @media (max-width: 768px)
        font-size: 10px

    span
      margin-left: 4px
      color: #656565
      font-size: 14px
      font-weight: bold
      white-space: nowrap

  &__best-seller
    position: relative
    display: inline-block
    margin-bottom: 8px
    padding: 3px 8px
    padding-right: 20px
    border-radius: 4px
    font-size: 14px
    font-weight: 700
    color: white
    background-color: #da6039

    @media (max-width: 768px)
      font-size: 12px

    &:after
      content: ""
      position: absolute
      right: -7px
      transform: rotate(45deg)
      top: 3px
      width: 15px
      height: 15px
      background-color: #fff

  &__category
    color: #1797ff

    @media (max-width: 768px)
      font-size: 12px

  &__img
    height: 100%
    max-width: 200px
    max-height: 130px

    @media (max-width: 768px)
      max-width: 150px

// BENEFITS
.product-benefits
  margin-bottom: 20px

  @media (max-width: 768px)
    display: none

  &__item
    display: flex
    margin-bottom: 10px
    line-height: 1.2

    .fa-check-circle
      margin-right: 6px
      font-size: 20px
      color: #2b89cc

// GUARANTEE
.product-guarantee
  display: flex
  align-items: center
  margin-bottom: 15px
  padding: 10px
  border: 2px solid #ffd000
  border-radius: 4px

  @media (max-width: 768px)
    display: none

  &__img
    height: 90px
    margin-right: 10px

  b
    display: block
    margin-bottom: 4px
    font-size: 15px

  p
    font-size: 14px

  &__delivery-text
    margin-bottom: 10px
    padding: 6px
    text-align: center
    color: red
    font-weight: bold
    font-size: 13px
    border: 2px solid red
    border-radius: 4px

// GALLERY
.product-gallery
  display: flex
  margin-bottom: 20px

  &__active
    width: 344px
    height: 344px
    display: flex
    align-items: center

    @media (max-width: 768px)
      width: 300px
      height: auto

.product-gallery-thumbs
  display: flex
  flex-direction: column
  margin-left: 10px

  &__item
    display: flex
    width: 64px
    height: 64px
    margin-bottom: 6px
    border: 1px solid #7c7c7c
    cursor: pointer

    @media (max-width: 768px)
      width: 50px
      height: 50px

    &:last-child
      margin-bottom: 0

.product-info-secure-image
  @media (max-width: 768px)
    display: none
