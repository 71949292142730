.page-error
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  z-index: 100
  color: black
  background-color: white
