.terms-modal
  ol
    margin-left: 40px
    list-style-type: decimal

    &.letter-list
      list-style-type: lower-latin

    li
      margin-bottom: 10px
