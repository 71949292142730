.container
  display: flex
  flex-direction: column
  width: 1000px
  max-width: 100%
  margin: 0 auto
  padding: 0 20px

.page-main-layout
  display: flex
  padding-top: 20px
  padding-bottom: 50px

  &__left
    width: 55%

    @media (max-width: 768px)
      width: 100%
      margin-bottom: 30px

  &__right
    width: 45%
    margin-left: 30px

    &--opc
      @media (max-width: 768px)
        display: none

    @media (max-width: 768px)
      width: 100%
      margin-left: 0

  @media (max-width: 768px)
    flex-direction: column-reverse

.knee-test-block
  display: flex
  align-items: center
  margin-bottom: 15px
  padding: 4px 10px
  border: 1px solid #cacaca
  background-color: #f7f7f7

  @media (max-width: 768px)
    display: none
    justify-content: center
    padding: 4px 5px
    margin-left: -20px
    margin-right: -20px
    margin-top: -10px

  &--mobile
    display: none

    @media (max-width: 768px)
      display: flex

  &__left
    margin-right: 10px

    @media (max-width: 768px)
      margin-right: 0

    img
      height: 80px

  &__right
    line-height: normal

    h6
      margin-bottom: 10px
      color: red
      font-size: 20px
      font-weight: bold

      @media (max-width: 768px)
        font-size: 16px

    p
      font-weight: bold

      @media (max-width: 768px)
        font-size: 12px

      span
        display: inline-block
        color: red

      i
        color: green
        font-size: 22px

.olc-reviews-desktop
  @media (max-width: 768px)
    display: none

.olc-reviews-mobile
  display: none

  @media (max-width: 768px)
    display: block
